.input {
  width: 50%;
  height: 32px;
  border-radius: 10px;
  font-size: 12px;
  text-align: center;
  margin: 8px auto;
  border: 1px solid var(--color-issabeline-grey);
  outline-color: var(--color-button-secondary);
  cursor: pointer;
}

.dateRangePickerContainer {
  text-align: center;
  width: 300px;
  max-width: 100%;
  margin: 0 auto;
  transition: 0.2s ease-in-out;
  @media (max-width: 767px) {
    width: 100%;
  }
}
