@import '../../../../variables';

.header {
  padding: 20px 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.button {
  cursor: pointer;
}

.title {
  color: var(--color-text-primary);
  font-weight: 700;
  font-size: 18px;
}

.stepName {
  font-size: 14px;
  color: var(--color-text-on-primary);
  background-color: var(--color-button-primary);
  padding: 8px;
  border-radius: 4px;
  cursor: pointer;
}

.titleWrap {
  display: flex;
  gap: 16px;
}
